import React from 'react';

import Header from './components/Header';
import { BackgroundLayout } from 'components';
import { InternalNotifications } from 'modules';

import { InternalNotificationsTypes } from 'constants/internalNotifications';

import './WatcherLayout.styles.scss';

const WatcherLayout = ({ children }) => {
  return (
    <BackgroundLayout className="np-watcher-layout">
      <Header />

      <div className="np-watcher-layout__body">
        <InternalNotifications disabledTypes={[InternalNotificationsTypes.USER]} />

        <div className="np-watcher-layout__body-content">{children}</div>
      </div>
    </BackgroundLayout>
  );
};

export default WatcherLayout;
