import React from 'react';
import { Link } from 'react-router-dom';

import { BannerAspectRatio } from 'constants/banner';

import './Banner.styles.scss';

const Banner = ({ src, alt, link }) => {
  const aspectRatio = BannerAspectRatio.join(' / ');

  const ImageComponent = <img src={src} alt={alt} style={{ aspectRatio }} className="np-banner" />;

  return link ? (
    <Link to={link} target="_blank">
      {ImageComponent}
    </Link>
  ) : (
    ImageComponent
  );
};

export default Banner;
