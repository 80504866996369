import React from 'react';

import Header from './components/Header';
import { InternalNotifications } from 'modules';
import { PageLoader, BackgroundLayout, Footer } from 'components';

import useRedirectNewUser from './hooks/useRedirectNewUser';
import useReferralToken from 'hooks/referral/useReferralToken';

import { LayoutBackgroundType } from './constants/background';

import './BaseLayout.styles.scss';

const BaseLayout = ({
  children,
  disableStickHeader,
  showFooter,
  showLogo,
  backgroundType = LayoutBackgroundType.SOLID,
}) => {
  useReferralToken();

  const { loading } = useRedirectNewUser();

  return loading ? (
    <PageLoader />
  ) : (
    <BackgroundLayout className="np-user-layout" backgroundType={backgroundType}>
      <Header sticky={!disableStickHeader} showLogo={showLogo} />

      <div className="np-user-layout__body">
        <InternalNotifications />

        <div className="np-user-layout__body-content">{children}</div>
      </div>

      {showFooter && <Footer />}
    </BackgroundLayout>
  );
};

export default BaseLayout;
