import React from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';

import { Logo } from 'components';
import UserMenu from './components/UserMenu';
import NavigationMenu from './components/NavigationMenu';
import { AccountsSummary, LanguageSelect, NetwokSummary, ProtectedWatcherContainer, ThemeSwitcher } from 'modules';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { publicUrls } from 'constants/urls';
import { WatcherPermissions } from 'constants/watcher';

import './Header.styles.scss';

const Header = () => {
  const { watcher } = useSelector(state => state.watcherAuth);
  const { isMobileView } = useWindowResponsiveness(900);
  const { isMobileView: hideSummary } = useWindowResponsiveness(1000);
  const { isMobileView: hideNetworkSummary } = useWindowResponsiveness(1200);

  const summaryAllowed = watcher?.isWlTypeMain;
  const isDashboardPage = location.pathname === publicUrls.observer.profile.main;

  const showLeftHeader = !isDashboardPage || isMobileView;

  return (
    <div className="np-watcher-layout-header">
      <div
        className="np-watcher-layout-header__body"
        style={{ justifyContent: !(isDashboardPage && !hideSummary) && !isMobileView && 'flex-end' }}
      >
        {showLeftHeader && (
          <div className="np-watcher-layout-header__left">
            {isMobileView && <NavigationMenu />}

            {isMobileView && <Logo url={publicUrls.main} />}
          </div>
        )}

        {isDashboardPage && !hideSummary && (
          <Space size={16} style={{ flexWrap: 'wrap' }}>
            {!hideNetworkSummary && <NetwokSummary />}

            {summaryAllowed && (
              <ProtectedWatcherContainer
                requiredPermissions={[
                  [WatcherPermissions.ADMIN],
                  [WatcherPermissions.ASSETS, WatcherPermissions.WORKERS, WatcherPermissions.EARNINGS],
                ]}
              >
                <AccountsSummary isWatcher />
              </ProtectedWatcherContainer>
            )}
          </Space>
        )}

        <Space size={isMobileView ? 'middle' : 'large'}>
          {!isMobileView && <LanguageSelect />}

          {!isMobileView && <ThemeSwitcher />}

          <UserMenu />
        </Space>
      </div>
    </div>
  );
};

export default Header;
