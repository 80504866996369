export const enLng = {
  translation: {
    menu: {
      minerRanking: 'Miner ranking',
      announcement: 'Announcements',
      powCommunity: 'PoW community',

      dashboard: 'Dashboard',
      workers: 'Workers',
      assets: 'Assets',

      language: 'Language',

      theme: {
        label: 'Theme',
        submenu: {
          light: 'Light',
          dark: 'Dark',
        },
      },

      userStatus: {
        vip: 'VIP',
        ambassador: 'Ambassador',
      },

      userProfile: {
        dashboard: 'Dashboard',
        workers: 'Workers',
        assetsAndPayments: 'Assets & Payments',
        bills: 'Bills',
        paymentSettings: 'Payment Settings',
        accountSettings: 'Account Settings',
        profile: 'Profile',
        subAccounts: 'Sub-accounts',
        watchers: 'Watchers',
        notifications: 'Notifications',
        referral: 'Referral',
        mining: 'Mining',
        depositsAndWithdrawal: 'Transfers & Withdrawals',
        apiSettings: 'API Settings',
        apiManagement: 'API Management',

        subAccountsInfo: {
          hashrate: 'Hashrate (24h)',
          workerStatus: 'Workers',
          active: 'Active',
          inactive: 'Inactive',
          offline: 'Offline',

          autoTransfers: {
            enabled: 'Auto transfers enabled',
            disabled: 'Auto transfers disabled',

            tooltip: {
              autoWithdrawal: 'Auto Withdrawal',
              autoInternalTransfer: 'Auto Internal Transfer',
              autoInternalTransferAccs: 'From {{count}} account(s)',
            },
          },
        },

        logOut: 'Log Out',
        logInBtn: 'Log In',
        signUpBtn: 'Sign Up',
      },
    },

    banners: {
      blockchainLifeInDubai: {
        title: 'Meet us at Blockchain<br/>Life in Dubai',
        location: 'Booth A10',
        date: '22-23 October',
      },
    },

    noMiners: {
      title: 'Your mining data will be displayed here',
      desc: "You'll get access to mining data when you connect at least one mining device",
      button: 'Connect your first device',
    },

    landing: {
      title: 'Completely new experience with Neopool',
      desc: 'Join our mining pool and experience the ease, convenience, and profitability of BTC mining firsthand.',
      startBtn: 'Start',
      exploreBtn: 'Explore',
      blueBadge: 'Minnovations in action!',

      coinsTable: {
        columns: {
          coin: 'Coin',
          poolHashrate: 'Pool Hashrate',
          networkHashrate: 'Network Hashrate',
          price: 'Price',
          dailyRevenue: 'Daily Revenue',
          minimumPayment: 'Minimum Payment',
          earningMode: 'Earning Mode',
        },

        dailyRevenue: {
          unit: 'per {{value}} per day',
        },
      },

      server: {
        title: 'Stratum Servers',
        earnMode: 'Earnings Mode and Fees',
        paymentTime: 'Payment Time',
        paymentTimeUnit: 'Every day {{time}} (UTC)',
        algorithm: 'Algorithm',
        dailyRevenue: 'Daily Revenue',
        difficulty: 'Difficulty',
        nextDifficulty: 'Next Difficulty Change Estimate',
        timeToDifficultyChange: 'Time Until Difficulty Change',

        successClipBoard: 'Server URL copied to clipboard',
        errorClipBoard: 'Error. Could not copy server URL to clipboard.',
      },

      chart: {
        totalHashrate: 'Network Hashrate',
        bitcoinPrice: 'Bitcoin Price',
      },

      // learnMore: {
      //   title: "Connect to the world's leading crypto currency pool",
      //   desc:
      //     'Suspendisse vehicula hendrerit magna, et ultrices nibh placerat nec.' +
      //     ' Phasellus id est vel magna dignissim pretium. Proin luctus volutpat massa sit amet lobortis.',
      //   firstBlock: {
      //     title: 'Donec semper',
      //     desc:
      //       'Suspendisse vehicula hendrerit magna, et ultrices nibh placerat nec.' +
      //       ' Phasellus id est vel magna dignissim pretium. Proin luctus volutpat massa sit amet lobortis.' +
      //       ' Pretium nisl nec aliquam. Ut id lectus a mauris tincidunt commodo eu vel massa.',
      //   },
      //   secondBlock: {
      //     title: 'Lacinia nibh',
      //     desc:
      //       'Suspendisse vehicula hendrerit magna, et ultrices nibh placerat nec.' +
      //       ' Phasellus id est vel magna dignissim pretium. Proin luctus volutpat massa sit amet lobortis.' +
      //       ' Pretium nisl nec aliquam. Ut id lectus a mauris tincidunt commodo eu vel massa.',
      //   },
      //   thirdBlock: {
      //     title: 'Etiam aliquam',
      //     desc:
      //       'Suspendisse vehicula hendrerit magna, et ultrices nibh placerat nec.' +
      //       ' Phasellus id est vel magna dignissim pretium. Proin luctus volutpat massa sit amet lobortis.' +
      //       ' Pretium nisl nec aliquam. Ut id lectus a mauris tincidunt commodo eu vel massa.',
      //   },
      // },
      // nextGen: {
      //   title: 'Next Gen for top blockchain',
      //   desc:
      //     'Donec semper diam mi, a lacinia nibh ultricies nec.' +
      //     ' Praesent nec rhoncus justo. Etiam aliquam pretium nisl nec aliquam.' +
      //     ' Ut id lectus a mauris tincidunt commodo eu vel massa.' +
      //     ' Donec vitae orci dictum, tincidunt orci ut, fermentum tellus.' +
      //     ' Donec rhoncus ante at lacus molestie, quis feugiat neque sodales. Sed semper feugiat ornare. ',
      //   expBtn: 'Get unique experience',
      //   block: {
      //     info: 'Test info',
      //     desc: 'Proin vestibulum ut nisl et porta',
      //   },
      //   banner: 'Etiam aliquam pretium',
      // },

      startEarning: {
        title: 'Start earning today',
        desc:
          'Suspendisse vehicula hendrerit magna, et ultrices nibh placerat nec. ' +
          'Phasellus id est vel magna dignissim pretium. Proin luctus volutpat massa sit amet lobortis.',
        registerBtn: 'Register',
        subTitle: 'Frequently asked questions',
        question: {
          title1: 'How much can I expect to earn with Neopool?',
          answer1:
            "Average income for the last 7 days on Neopool was {{value}} per TH/s per day. Your mining income will vary day-to-day and will heavily depend on several factors: your device's hashrate and reject rate, mining difficulty, transaction fees, reward calculation method, applied pool commissions, and even luck. For now Neopool is one of the most efficient pools: it offers reward calculation via FPPS method which usually brings better profit and alleviates luck-dependency of your income. Moreover, after you get VIP status the pool commission will start to decrease substantially. A nice source of extra income for you is our Referral Program. By refferring your friends to join our pool you'll receive rewards even if you're not mining yourself. More information on our Referral program can be found on the Referral Rewards page.",
          title2: 'How do I become a VIP member?',
          answer2:
            "Our VIP program rewards the most active workers with an average hashrate of 1 PH/s and more. As soon as you connect your devices and achieve 1 PH/s as an average hashrate you can apply for a VIP membership here: <1>{{link}}</1>. Our BD will contact you with the information on all the bonuses you're qualified to get.",
          title3: 'What are the benefits of participating in your Referral Program?',
          answer3:
            "The main benefit of the <1>{{link}}</1> is referral reward. Referral reward is a percentage of the pool commission that we send to a referrer's account as a way to say thank you for inviting a friend. And after 5 or more of your friends join us you'll reach the Ambassador tier and the reward gets even bigger.",
          title4: 'How do I start mining with Neopool?',
          referralRewardLink: 'Referral Program',
          answer4:
            "To start mining Bitcoin you need a Neopool account. If you don’t have an account yet, please click the <SignUpLink>Register</SignUpLink> on our main page. You will be asked to verify your e-mail address and to set up a password. Then you'll choose a username which will also serve as the name of your first account. Your next step is to configure your mining devices. You'll need to connect your device to the network using LAN, log into the configuration page of your worker, enter the Stratum URL and the workers's name. You'll find a step-by-step guide on your <WorkersLink>workers page</WorkersLink>. And that's it - you're all set!",
        },
      },
    },

    // footer: {
    //   desc: 'Technology brings infinite possibilities to the blockchain world',
    //   product: {
    //     title: 'Products',
    //     app: 'APP',
    //     community: 'POW Community',
    //   },
    //   support: {
    //     title: 'User Support',
    //     link1: 'Donec',
    //     link2: 'Mauris tellus sem',
    //     link3: 'Morbi pellentesque',
    //   },
    //   patterns: {
    //     title: 'Strategic Partners',
    //     link1: 'Etiam aliquam',
    //     link2: 'Lorem ipsum',
    //     link3: 'Curabitur rhoncus',
    //     link4: 'Etiam in volutpat ex',
    //   },
    // },

    signIn: {
      title: 'Log In',
      passwordRequired: 'Please enter your password',
      forgotPassword: 'Forgot your password?',
      desc: 'Are you new here?',
      signUpLink: 'Sign up',
    },

    signUp: {
      title: 'Sign Up',
      email: 'E-mail',
      agreement: 'I accept the <Link>User Agreement</Link>',
      agreementWarn: 'Please read and accept the User Agreement',
      signUpBtn: 'Sign Up',
      statusSuccess: 'Success',
      statusSuccessDesc: "We've sent you an email with the verification link. Please follow the link to continue.",
      desc: 'Already have an account?',
      logInLink: 'Log In',

      agreementModal: {
        header: {
          title: 'Dear user,',
          text1:
            'By clicking Agree and Continue, you hereby agree and consent to the User Agreement, its policies, and the Privacy Policy.',
          text2:
            'Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service',
        },

        document: {
          userAgreement: {
            title: 'Neopool Service User Agreement',
            text: {
              part1:
                'The purpose of this User Agreement (hereinafter the “Agreement”) is to ensure that the proper elements and commitments are in place to provide a consistent and mutually beneficial business relationship between the Miner and <strong>NEOPOOL LTD</strong> (collectively the “Parties”).<br/>The goal of this Agreement is to obtain mutual agreement for mining pool service between the Miner and <strong>NEOPOOL LTD</strong>.',
              part2: 'The objectives of this Agreement are to:',
              part3:
                'Provide clear reference to service ownership, accountability, roles and/or responsibilities<br/> Present a clear, concise and measurable description of service, operational, and compliance obligations between the parties.<br/>Match perceptions of expected service provision with actual service support & delivery.',
            },
          },

          termOfUse: {
            title: 'TERMS OF USE',
            text: {
              part1:
                'These Terms of Use (“Terms of Use”) constitute a legally binding agreement, whether personally or on behalf of an entity (“you”, “miner” or the “user”) and <strong>NEOPOOL LTD</strong>, a company duly incorporated and registered under the laws of Seychelles, Company number 240364, Registered Agent and address: Apollo Business Solutions (Pty) Ltd, 103 Sham Peng Tong Plaza, Victoria, Mahé, Seychelles ("Company").<br/>By accessing and using the Pool, Miner accepts and agrees to the Terms and Conditions, and the Company confirms its commitments to Miner (collectively, the “Service Agreement”). As the operator of the Pool, Neopool shall provide a mining Pool Service (as defined below) to Miner under the Service Agreement.',
              part2:
                'Miner agrees that the Company will have the right to modify the Terms of Use at any time, without notification to the Miner. Miner agrees to be solely responsible for reviewing the Terms of Use and/or any modifications thereto.',
              part3:
                'By registering as a User and/or submitting or providing information to the Company, you acknowledge that:',
              part4:
                'you have read, understood and agreed to be bound by all the provisions of the Terms of Use and the Privacy Policy, as may be amended from time to time; and that no promises or representations have been made to you to induce you to accept these terms.',
              part5:
                'If you do not agree with these Terms of Use, please stop using this Application immediately or any of the Company’s Services.',
            },
          },

          definitions: {
            title: '1. Definitions',
            text: {
              part1:
                'In these Terms of Use, unless the context otherwise requires, the following words and expressions shall have the following meanings:',
              part2:
                '<strong>“Agreement”</strong> means any agreement Miner have entered into with the Company for miner’s use of any Services;',
              part3:
                '<strong>“Application”</strong> means the Company website at https://www.neopool.com (and other subdomains of the main Company’s domain “neopool.com”) and the Company software application which may be downloaded by miner from platforms (such as Apple App Store and Google Play) as the Company may determine from time to time for use on your smartphone or other mobile devices;',
              part4:
                '<strong>“Confidential Information”</strong> means all information in any and all mediums disclosed by one party to another party including, without limitation, data, technology, know-how, inventions, discoveries, designs, processes, formulas, models, equipment, algorithms, software programs, interfaces, documents, specifications, information concerning research and development work, and/or trade and business secrets, current, planned or proposed products, marketing and business plans, forecasts, financial information, and shall include User Data;',
              part5:
                '<strong>"Force Majeure"</strong> means any event or circumstance, or any combination of events or circumstances, which are not attributable to the Company, including but not limited to malfunctions of the Internet or other telecommunications facilities, failures by (third) parties on which the Company depends when providing the Services, the defective condition of items, equipment, software, personal data, or other materials which you have instructed the Company to use and/or the non-availability of one or more members of the Company staff (due to illness, strikes or otherwise).',
              part6:
                '<strong>"Privacy Policy"</strong> means the statement that sets out the Company’s policy on collection, use and disclosure of personal data and which can be accessed at Privacy Policy;',
              part7:
                '<strong>“Service”</strong> means all or any of the Services provided by Company to Miner including but not limited to the services, content and transaction capabilities on the Application;',
              part8:
                '<strong>“User”</strong> means a user of the Application, whether an individual or a business entity, who has registered for an account through the Application;',
              part9:
                '<strong>“User Data”</strong> means information (including personal information) relating to a User, including but not limited to order information, payment information, account information, preferences and tendencies.',
            },
          },

          termsOfService: {
            title: '2. Neopool Terms of Service',
            text: {
              part1:
                'NEOPOOL LTD provides products, services, and technologies through Neopool Platform the (“Neopool Platform”). These terms of service constitute the entire agreement between the parties (together, the “Terms”). In the event of any inconsistency between these Terms and any of the other addenda, terms, and documents, the provisions of these terms of services shall prevail to the extent of such inconsistency. All other information provided on the Neopool Platform or verbal/written statements made are excluded from these Terms and do not constitute a legal agreement between the parties. Use of the Neopool Platform and the services is governed by these Terms.',
            },
          },

          AmlKycCtfPolicy: {
            title: 'AML / KYC / CTF policy',
            text: {
              part1:
                'This Know-your-Customer and Anti-money Laundering and counter-terrorist financing Policy and ("KYC/AML/CTF Policy") outlines the procedures and mechanisms used by Neopool to prevent money laundering and doing business with illicit counterparties.',
              part2:
                'Our KYC/AML/CTF Policy is guided by the Anti-Money Laundering and Countering the Finance of Terrorism Act 2020, including all of its amendments up to 2024, issued by the Seychelles Financial Intelligence Unit, specifically, the AML and Counter-Terrorist Financing (“CTF”) provisions, as mandated by other applicable directives and decrees, and all other rules and regulations regarding KYC, AML and CTF, as well as otherguidelines of the Federal Service Authority.',
              part3:
                'The Company adheres to Financial Action Task Force (FATF) Recommendations that are recognized as the international standard for combating money laundering and the financing of terrorism and proliferation of weapons of mass destruction.',
              part4:
                'The Company will request and collect all necessary KYC and due diligence documents for each Miner so as to enable the Company to full perform their duties and obligations under the Law, the Directives and the AML Law.',
              part5:
                'It is of critical importance for the Company’s integrity and reputation, to be able to identify, report, and take precautions to guard against money laundering and financing of terrorism. The nature of the Company’s business requires it to abide by anti-money laundering (AML) and countering the financing of terrorism (CFT) legislation and regulation that apply to its activities. In addition, the Company may be particularly attractive to individuals seeking to clean-up money due to non-face-to-face nature of the services.',
              part6:
                'In view of the above, You are not allowed to access or use the Neopool Platform if any part of your mining farm is located in, you are located in, incorporated or otherwise established in, or a resident of: (i) any country or other jurisdiction that are subject to sanctions that prohibit You from conducting business in accordance with these Terms, and those included in EU sanction list; (ii) a jurisdiction where it would be illegal according to Applicable Law for you (by reason of your nationality, domicile, citizenship, residence or otherwise) to access or use the Neopool Platform; or (iii) where the publication or availability of the Neopool Platform is prohibited or contrary to local law or regulation, or could subject any member of Neopool to any local registration or licensing requirements (together, the “Restricted Jurisdictions”). Neopool may, in its sole discretion, implement controls to restrict access to the Neopool Platform in any of the Restricted Jurisdictions. If Neopool determines that you are accessing the Neopool Platform from any Restricted Jurisdiction, or have given false representations as to your location of incorporation, establishment, citizenship or place of residence, Neopool reserves the right to close any of your accounts immediately and freeze all of your account balances.',
              part7:
                'You hereby authorize us, or a third-party service provider, to take any measures that we consider necessary to perform background checks on yourself and/or your institution. We screen individuals and institutions that are illicit actors, politically exposed, sanctioned, and/or have adverse media outstanding. Failing to comply with KYC/AML policies and background checks provides Neopool the right to close any of your accounts and/or freeze all of your account balances.',
              part8:
                'You hereby authorize us, or a third-party service provider, to take any measures that we consider necessary to monitor and screen your Bitcoin and other cryptocurrency address for the detection of illicit activity. We monitor miner payout addresses on an ongoing basis.',
              part9:
                'If at any point we deem your account to be associated with any illicit activity we will take the appropriate measures to close any of your accounts immediately and freeze all of your account balances and report suspicious transactions to the appropriate authority as required by Law.',
              part10:
                'The Parties will not, directly or indirectly, in connection with this Terms of use, offer, pay, promise to pay or authorize the giving of money or any other form of consideration to or for the benefit of (a) any governmental official, (b) any political party, (c) any nominee for public office, (d) any employee, officer or director of an enterprise that is directly or indirectly controlled by or is owned by a governmental body, or (e) any relative of any of the foregoing persons, while knowing or being aware of a high probability that all or a portion of such money or other consideration is, or will be used, for the purpose of influencing a governmental official in his or her official capacity, in order to induce a governmental official to do or omit to do an act in violation of his or her lawful duty or to secure an improper advantage in order to assist in obtaining or retaining business for or with, or directing business to, any person.',
              part11:
                'The Miner will defend, indemnify and hold the Company and its affiliates harmless from and against any and all claims, losses, damages, expenses and liabilities, including attorney’s fees, arising from its failure to comply with the provisions of the Terms of use. For the avoidance of doubt, the foregoing obligation to remedy losses applies to claims based on violations and alleged violations of the Convention on Combating Bribery of Foreign Officials in International Business Transactions (OECD Convention) and/or the national laws applicable to the relevant governmental official(s).',
              part12:
                'We require you not to share your password, give access to your Neopool account to others, or transfer your account to anyone else. Only individuals or institutions that have opened an account (“Neopool Account”) and successfully completed our KYC/AML/CTF checks are considered Neopool customers. If you would like to provide access to your Neopool Account to another individual or institution, this individual or institution must register a user account with Neopool and successfully complete our KYC/AML/CTF checks. You agree that you will not allow any persons who have not successfully completed our KYC/AML/CTF checks to access or use your Neopool Account.',
              part13:
                'All records shall be kept by the Company for a minimum of five (5) years which period runs from date of execution of the transaction or the termination of the business relationship as the case may be. This period may be extended in circumstances where the Company or relevant government authority is carrying on an investigation of a reported suspicious transaction.',
            },
          },

          services: {
            title: '3. Neopool Services',
            text: {
              part1:
                'Neopool uses its own system, through the Internet and other means to provide Miner with a digital currency mining Pool and other services/products that may be added based on the Pool site (“Service”).',
              part2:
                'Miner hereby authorizes Neopool to be fully responsible for payouts of the profit from such value-added Service',
              part3:
                'The Company shall not be held liable for any fault, omission or breach of contract of a third-party service provider.',
            },
          },

          minerRightsAndObligations: {
            title: '4. Miner Rights and Obligations',
            text: {
              part1:
                '<strong>a.</strong> Miner shall be responsible for preparing the necessary equipment and bear the expenses related to using such necessary equipment to participate in the Pool and Service.',
              part2:
                '<strong>b.</strong> Miner agrees to provide legal, true, accurate and detailed personal information, and update such information as needed.',
              part3:
                '<strong>c.</strong> Miner shall comply with all applicable laws, rules, and regulations related to the use of the Pool and Service.',
              part4:
                '<strong>d.</strong> Miner shall disclose the use of any proxies or agents that are configured between the Mining Pool and the ASIC. Company reserves the right to withhold Miners pending balance in the event a Miner has not configured their proxy or agent correctly.',
              part5:
                '<strong>e.</strong> Miner acknowledges and agrees that it is using the Pool and Service at its own risk.',
              part6:
                '<strong>f.</strong> In the event Miner’s access and/or rights to the Pool and Service have been discontinued, Miner is solely responsible for settling the remaining balances left in its account. Company shall use commercially reasonable efforts to assist Miner with settling any remaining balances in Miner’s account.',
              part7:
                '<strong>g.</strong> For the avoidance of doubt, Company shall not be responsible or liable to Miner for any balances remaining in Miner’s account three (3) months after Miner’s access and/or rights to the Pool and Service have been discontinued (regardless of whether the balances were left in Miner’s account intentionally).',
              part8:
                "<strong>h.</strong> It is the responsibility of Miner to ensure they have built redundancy into their mining operation to hedge against outages, system failures, and Force Majeure. As a Miner using the Pool and Service, Miner must configure a non-Neopool as backup/failover pool in all Miner machines pointed at Neopool stratums. In the event of a Pool outage that results in Pool downtime in excess of Neopool's SLA uptime guarantee of 98% monthly hashrate, financial compensation from Neopool to Miner for the outage will be capped at 8 hours of the FPPS value of Miner’s average total hashrate on a 144 block look-back basis, or, using the same compensation methodology, the value of lost hashrate accrued between Pool outage occurrence and the time Miner machines redirect their hashrate to the backup/fail-over pool, whichever is shorter.",
              part9:
                '<strong>i.</strong> Despite our trying to make our services broadly available to everyone, You agree that you cannot use Neopool if:<br/>- You carry out any activity that: (i) involves proceeds from any illegal or unlawful activity (including activities relating to cryptocurrency tumblers, darknet markets, money laundering or terrorism financing); (ii) publishes, distributes or disseminates any illegal or unlawful material or information; or (iii) otherwise violates, or could violate, any applicable laws;<br/>- Your legal capacity has not been limited to the extent that would prevent you from undertaking the obligations assumed by these Terms;<br/>- You are under the age of 18;<br/>- We previously disabled your account for violation of our Terms;<br/>- You are in Restricted Jurisdictions;<br/>- The legal entity you are acting on behalf is not duly established and existing under the law that governs its jurisdiction;<br/>- You are not authorized to act on behalf of the legal entity and to undertake these Terms.',
              part10: 'If you are acting on behalf of a legal entity, you acknowledge the following:',
              part11:
                '- Your legal capacity has not been limited to the extent that would prevent you from undertaking the obligations assumed by these Terms.',
              part12:
                'You acknowledge that you are fully responsible for obtaining necessary information about tax or similar obligations arising from the use of our services, products or software. You also acknowledge that we are not responsible for obtaining the mentioned information nor for the fulfillment of such tax (or similar) obligations.',
              part13:
                'You agree not to abuse or exploit by any means our services, products or software. This includes but is not limited to mistakes, programming bugs, withholding attacks, botnet attacks, Denial-of-Service attack (DDoS) or errors in a cryptocurrency network to take unfair advantage of us or other users in our platform in bad faith.',
              part14: 'The Company does not permit those under 18 to use the Service.',
              part15:
                '<strong>j.</strong> You are responsible for maintaining the confidentiality of any login information associated with any account you use to access the Services, and any Wallet backups, and any 2-factor authentication credentials. You are responsible for all activities that occur under the account(s) associated with you. You agree not to divulge your account details, including your password, to anyone. We cannot and will not be liable for any loss or damage from your failure to maintain the security of your account and password. If you become aware of any unauthorized use of your password or of your account, you agree to notify us immediately support@neopool.com.',
              part16:
                '<strong>k.</strong> You shall not access (or attempt to access) the Services by any means other than through the means we provide or have indicated to be used. You specifically agree not to access (or attempt to access) any Services via any illegal and unlawful means, or use any Services in an illegal or unlawful manner, or use any Services to support purposes that are illegal or unlawful (such as but not limited to terrorism, fraud, money laundering or other criminal or unlawful activity).',
            },
          },

          serviceChargesAndPayment: {
            title: '5. Service Charges and Payment',
            text: {
              part1: 'You shall pay all fees according to the terms set out in the applicable Agreement.',
              part2:
                'You acknowledge that with respect to several Services, each Service is only rendered after the relevant fee has been paid, as set out in the applicable Agreement.',
              part3:
                'The Company reserves the right to change the applicable fees, and the fee structure or arrangement under the Agreement at any time. Only if required under applicable law, the Company will notify you of any such change(s) in advance.',
              part4:
                'To the extent permitted by applicable law, your payment obligations are non-cancelable and non-refundable. You shall be required to pay, on behalf of the Company, any sales, value-added or other similar taxes payable by the Company based on the Services ordered. The Miner – individual person – is also obliged to pay all the relevant taxes, duties and levies as prescribed by the current legislation, on its own.',
              part5:
                'The Company shall be entitled to set-off any amount payable by the Company to you against any amount payable by you to the Company. You shall not be entitled to set-off any amount payable by you to the Company against any amount payable by the Company to you.',
              part6:
                "If you fail to pay any invoices timely, in addition to the Company's other remedies, the Company may suspend or terminate access to and use of the Services.",
              part7:
                '<strong>Fees</strong><br/>Miner will be paid for Bitcoin hashrate using a Full Pay Per Share (FPPS) payment methodology, defined as the expected value of both block reward and block transaction fees. Pool fees will be calculated based on the FPPS methodology.',
            },
          },

          confidentiality: {
            title: '6. Confidentiality',
            text: {
              part1:
                'Both Parties agree not to disclose any Confidential Information from the Pool and/or Service. “Information" includes (but is not limited to) information regarding Neopool’s Pool, Miner’s data, Service, Confidential documentation, software, trade secrets embodied therein and any other written or electronic information that is either (i) marked as confidential and/or proprietary, or which is accompanied by written notice that such information is confidential and/or proprietary, or (ii) not marked or accompanied by notice that it is confidential and/or proprietary but which, if disclosed to any third party, could reasonably and foreseeably cause competitive harm to the owner of such information. Confidential Information shall not include information which is: (i) publicly available, (ii) lawfully obtained by a party from third parties without restrictions on disclosure, or (iii) independently developed by a party without reference to or use of the Confidential Information.',
              part2:
                'All Confidential Information identified in accordance with the provisions of this clause shall remain the property of the disclosing party and shall be destroyed or returned to the disclosing party (at the selection of the disclosing party) upon written request. The disclosing party’s failure to request such return or destruction, shall not relieve the receiving party of its confidentiality and non-use obligations.',
              part3:
                'The parties acknowledge that for the purposes of data protection legislation (Data protection Bill, 2023, Seychelles (Bill No. 19 of 2023), General Data Protection Regulation (EU) 2016/679 (“GDPR”) or equivalent, and other applicable data protection laws and regulations), both parties are deemed to be separate Controllers in respect of the personal data of their contact persons and other personnel (where the Controller has the meaning defined in data protection legislation).',
              part4:
                "Each party, as a separate Controller, warrants that it will comply with all applicable requirements of the data protection legislation, as follows:<br/>it has the legal basis for the transfer of personal data of its contact persons to the other party, and for the processing of personal data of the other party's contact persons, obtained in the performance of this Agreement;",
              part5:
                "- it will inform the other party's contact persons about the processing of their personal data for the purposes of performing this Agreement;",
              part6:
                '- it will inform its contact persons under this Agreement about the transfer of their personal data to the other party for the purpose of performing this Agreement;',
              part7:
                "- it will acquaint its contact persons under this Agreement with the other party's Privacy Notice and send the other party a list of acquainted persons.",
            },
          },

          suspensionAndTermination: {
            title: '7. Suspension and Termination',
            text: {
              part1:
                'You hereby agree that the Company is entitled to suspend or to terminate your use of the Services immediately, without notice (unless notice is required under the applicable law), and for any reason including but not limited to:',
              part2:
                '<strong>(a)</strong> during planned or unplanned downtime for upgrades and maintenance to the Services in case of occurrence of a Force Majeure event;<br/><strong>(b)</strong> if the Company suspects or detects any malicious code, software or a security issue, connected to your Wallet or your use of the Services;<br/><strong>(c)</strong> if You breach any material term of: this Agreement, these Terms of Use, and/or any other agreement with the Company.',
              part3:
                'For the avoidance of doubt, subject to the aforesaid, if the Company is entitled to terminate your use of the Services, the Company shall terminate the application of these Terms of Use to you.<br/>The Company shall also be entitled to suspend or terminate your use of the Services in the manner set out in the applicable Agreement.',
              part4:
                'For the avoidance of doubt, the Company shall not be required to compensate you or reimburse or cover any cost incurred by you in relation to the termination or suspension of your use of the Services.',
              part5: 'Upon termination of the application of these Terms of Use to you:',
              part6:
                '<strong>(a)</strong> each Party shall return to the other Party, or destroy (and provide certification of such destruction), all property of the other Party in its possession or control (including all Confidential Information);<br/><strong>(b)</strong> all rights granted to you hereunder will immediately cease; and<br/><strong>(c)</strong> the Company will have the right but not the obligation to delete your account.',
            },
          },

          disclaimerOfWarranty: {
            title: '8. Disclaimer of warranty',
            text: {
              part1:
                'You acknowledge that your use of Neopool Platform is at your sole risk and discretion. All materials, information, products, Services, software and other content contained in Neopool Platform, or any materials that are obtained from a linked site is provided "as is" without warranty or conditions of any kind, whether express or implied (including but not limited to, the implied warranties or conditions of merchantability and fitness for a particular purpose, non-infringement, security or accuracy).',
              part2:
                'You assume total risk and responsibility for your use of the Neopool Platform and the Services provided herein, including the submission of any personal information (where applicable). Except as expressly stated in these Terms of Use, all warranties, conditions and other terms, whether express or implied, by statute, common law or otherwise are hereby excluded to the fullest extent permitted by law.',
              part3:
                'You acknowledge that some of the Services, as an internet-delivered software application, may experience periods of downtime, including but not limited to scheduled maintenance. The Company makes no representations or warranties, whether express, implied or statutory, with respect to the Application/Services provided hereunder, including the Wallet and any documentation, content, data and materials made available with the Services. We do not warrant or represent that your access to and/or use of the Neopool Platform and/or of any Service will be uninterrupted (whether due to any temporary and/or partial breakdown, repair, upgrade or maintenance of the Neopool Platform or otherwise) or error-free or that any information, data, content, software or other material accessible through the Application/Services will be free of bugs, viruses, worms, Trojan horses or other harmful components.',
              part4:
                'We specifically disclaim compatibility between the Neopool Platform and any software or hardware.',
              part5: 'Any content you download from our Application/Services is done so at your own discretion.',
              part6:
                'No information or advise, whether expressed implied, verbal or written, obtained by you from the Company, the Application or through any Services we provide, shall create any warranty, guarantee, or condition of any kind, except for those expressly outlined in these Terms of Use or in the Agreement.',
            },
          },

          indemnity: {
            title: '9. Indemnity',
            text: {
              part1:
                'By agreeing to the Terms of Use, you agree that you shall defend, indemnify and hold the Company, its licensors and each such party’s parent organizations, subsidiaries, affiliates, officers, directors, members, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including legal fees and costs and/or regulatory action) arising out of or in connection with: (a) your use of the Service, and/or the Application in your dealings with third-party merchants, providers, partners, advertisers and/or sponsors, or (b) your violation or breach of any of the Terms of Use or any applicable law or regulation, whether or not referenced herein, or (c) your violation of any rights of any third party arranged via the Service, or (d) your use or misuse of the Service and/or the Application; save to the extent arising out of the fraud, negligence or willful misconduct of the Company.',
            },
          },

          nonExclusivity: {
            title: '10. Non-exclusivity',
            text: {
              part1:
                'Nothing in these Terms of Use is intended to create, nor shall it be construed as creating, any exclusive arrangement between the parties to these Terms of Use. These Terms of Use shall not restrict either Party from entering into similar arrangements with others, provided it does not breach its obligations under these Terms of Use by doing so, including without limitation, any confidentiality obligations. In particular, the Company shall be entitled to develop, acquire, license and to market and distribute materials, products or services which are competitive with your products or services, provided that the Company does not use your Confidential Information in so doing.',
            },
          },

          forceMajeure: {
            title: '11. Force Majeure',
            text: {
              part1:
                'Both Parties shall not be liable for any non-performance of its obligations pursuant to the Service Agreement if such non-performance is caused by a Force Majeure event. In case of a Force Majeure event, both Parties have the right to suspend or terminate its services immediately under the Service Agreement. “Force Majeure” events shall mean any event or circumstance, or any combination of events or circumstances which are beyond the control of the Parties. Such events or circumstances shall include, but are not limited to events or occurrences that delay, prevent or hinder the Parties from performing such obligations, cyberattacks, acts of God, and regulatory, administrative or similar action or delays to take actions of any governmental authority.',
              part2:
                'On the occurrence of any event of Force Majeure the Party so affected shall as soon as reasonably possible: give a written notice of such event to the other Party by letter, telex or fax and resume full performance of its obligations under the Contract as soon as possible, and so notify the other Party thereof.',
              part3:
                'If, due to a Force Majeure event, one or the other Party could not accomplish its contractual obligations during a period of more than three (3) calendar months, the Parties shall meet and find an agreement within the shortest possible time upon the conditions on which they could continue the execution of the Contract. It is agreed that during the time of suspension due to the force majeure event, each Party shall bear its own costs',
            },
          },

          thirdPartyRights: {
            title: '12. Third Party Rights',
            text: {
              part1:
                'All the terms and provisions of these Terms of Use shall be binding upon and inure to the benefit of the parties to these Terms of Use and to their respective heirs, successors, permitted assigns and legal representatives. The Company shall be permitted to assign these Terms of Use without notice to or consent from you. You shall have no right to assign or otherwise transfer these Terms of Use, or any of its rights or obligations hereunder, to any third party without the Company’s prior written consent, to be given or withheld in the Company’s sole discretion.',
            },
          },

          severabilityAndWaiver: {
            title: '13. Severability and Waiver',
            text: {
              part1:
                'No waiver by us of any of these Terms of Use or default of yours under these Terms of Use shall operate or be construed as a waiver by us of any future defaults or provision.',
              part2:
                'If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.',
              part3:
                "Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.",
              part4:
                'These Terms and Conditions are translated into Russian. You agree that the original English text shall prevail in the case of a dispute.',
            },
          },

          entireAgreement: {
            title: '14. Entire agreement',
            text: {
              part1:
                'These Terms of Use contains all the terms agreed between the parties regarding its subject matter and supersedes and excludes any prior agreement, understanding or arrangement between the parties, whether verbal or in writing.',
            },
          },

          governingLawAndDisputeResolution: {
            title: '15. Governing Law and Dispute Resolution',
            text: {
              part1:
                'This Agreement shall be governed by and construed in accordance with the laws of Seychelles, without giving effect to any choice of law or conflict of law provision or rule that would cause the application of the laws of any jurisdiction other than Seychelles. The Parties agree to submit to the exclusive jurisdiction of the courts located in Seychelles for any disputes arising out of or relating to this Agreement.',
              part2:
                'In the event of any dispute or difference arising out of or in connection with or in relation to these Terms of Use, including any question regarding the existence, validity, application or interpretation of these Terms of Use or any of its provisions, both parties shall endeavor to settle the dispute informally by agreement between the parties. Both parties shall always act in good faith and co-operate with each other to resolve any disputes.',
            },
          },

          intellectualProperty: {
            title: '16. Intellectual Property',
            text: {
              part1:
                'The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.',
              part2:
                'The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.',
              part3:
                'The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.',
            },
          },

          linksToOtherWebsites: {
            title: '17. Links to Other Websites',
            text: {
              part1:
                'Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.',
              part2:
                'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.',
              part3:
                'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.',
            },
          },

          yourFeedbackToUs: {
            title: '18. Your Feedback to Us',
            text: {
              part1:
                'You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.',
            },
          },
        },
      },
    },

    registration: {
      title: 'Sign Up',
      wrongEmailTitle: 'Your e-mail verification link is invalid or expired',
      wrongEmailSubTitle: 'If you require help with signing up, feel free to contact our support team.',
    },

    forgotPassword: {
      title: 'Reset Password',
      email: 'E-mail',
      statusSuccess: 'Success',
      statusSuccessDesc: 'We’ve sent you an e-mail with a link. Please follow the e-mail to reset your password.',
    },

    newPassword: {
      title: 'Reset Password',
      statusSuccess: 'Success',
      successDesc: 'Password changed',
      statusError: 'Error',
      errorDesc:
        'Your verification link is invalid or expired. If you require help with password reset, feel free to contact our support team.',
    },

    dashboard: {
      title: 'Dashboard',
    },

    profitDetails: {
      title: 'Income Details',

      tableHeaders: {
        date: 'Date (UTC)',
        hashrate: 'Hashrate',
        totalEarning: 'Total Income',
        unitOutput: '24h Unit Output (per TH/s)',
        ppsProfit: 'PPS Income',
        pplnsProfit: 'PPLNS Income',
        soloProfit: 'Solo Income',
        actions: 'Actions',
      },
    },

    revenueHistory: {
      title: 'Revenue Sharing History',

      tableHeaders: {
        time: 'Time',
        type: 'Type',
        miningAccount: 'Account',
        miningProfit: 'Mining Income',
        recipientAccount: 'Recipient Account',
        proportionOfSharing: 'Amount',
        amount: 'Balance',
      },
    },

    afterSaleService: {
      title: 'Customer Support',
      emailTitle: 'E-mail',
      emailValue: 'support@neopool.com',
      telegramTitle: 'Telegram',
      telegramValue: 'neopoolcom',
    },

    accountInfo: {
      title: 'Balance',
      profit: 'Last 24-Hour Revenue ({{coin}})',
      totalEarnings: 'Total Income ({{coin}})',
      accountBalance: 'Account Balance ({{coin}})',
      myBills: 'My Bills',
    },

    hashrate: {
      title: 'Hashrate',
      tenMinAvg: '10 Min Average',
      hourAvg: '1 Hour Average',
      dayAvg: '1 Day Average',
    },

    worker: {
      title: 'Workers',
      seeAll: 'See all',
      active: 'Active',
      inactive: 'Inactive',
      offline: 'Offline',
    },

    networkSummary: {
      title: 'Network Summary',
      bitcoinPrice: 'Bitcoin price',
      networkHashrate: 'Network hashrate',
      networkDifficulty: 'Network difficulty',
    },

    accountsSummary: {
      title: 'All accounts',
      hashrate24h: 'All accounts. Hashrate (24h)',
      hashrate10m: 'Hashrate (10m)',
      workers: 'All accounts. Workers',
      balance: 'All accounts. Balance',
      activeWorkers: 'Active Workers',
      inactiveWorkers: 'Inactive Workers',
      offlineWorkers: 'Offline Workers',
    },

    profitChart: {
      title: 'Income Chart',
      legendName: 'Income per Day',
    },

    hashrateChart: {
      title: 'Hashrate Chart',

      legend: {
        hashRate: 'Hashrate',
        rejectRate: 'Reject Rate',
      },

      tooltip: {
        hashRate: 'Hashrate',
        rejectRate: 'Reject Rate',
      },

      threeHours: '3 hours',
      oneDay: '1 day',
      sevenDays: '7 days',
      oneMin: '1 min',
      tenMin: '10 min',
      oneHour: '1 hour',
    },

    accountSettings: {
      basicInfo: {
        title: 'Personal Information',
        newMobile: 'New phone number',
        newEmailLabel: 'New e-mail',
        mobileRequestSuccess: 'Success. Mobile changed.',
        emailRequestSuccess: 'Success. e-mail changed',
      },

      accountSecurity: {
        title: 'Account Security',
        authenticator: 'Two-Factor Authentication',
        signInPassword: 'Sign In Password',
        paymentPassword: 'Payment Password',
        enableBtn: 'Enable',
        disableBtn: 'Disable',
        successClipBoard: 'Reset codes copied to clipboard',
        errorClipBoard: 'Recovery codes not copied. Please refresh the page and try again.',
        clipBoardBtn: 'Copy recovery codes',
        passwordChangeSuccess: 'Success. Password changed.',

        form: {
          recoveryCodes:
            'These are your recovery codes which will act as a one-time substitute to the GA code in case you loose access to your Google Authenticator app. Please store them safely offline or on a separate device. Each code can only be used once.',
          successEnableMessage: 'Success. Two-factor authentication enabled.',
          successDisableMessage: 'Success. Two-factor authentication disabled.',

          twoFAEnable: {
            title: 'Enable two-factor authentication',

            steps: {
              downloadApp: 'Download the app',
              securityCheck: 'Bind the account',
              boundSuccessfulley: 'Done',
            },

            formSteps: {
              downloadApp: 'If you do not have the Google Authenticator app please install it on your device. ',
              scanBarcode: 'Open the Google Authenticator application and scan the QR-code:',
              enterKey: 'Open the Google Authenticator application and enter the key:',
              enterCode: 'Enter the code provided by the application and click "Submit" to finish the setup.',
            },

            button: {
              unableToScan: 'Unable to scan?',
              scanQrCode: 'Scan QR-code?',
            },
          },

          twoFADisable: {
            title: 'Disable Google Authenticator',
          },

          paymentPassword: {
            setUp: {
              title: 'Set Up Payment Password',
            },

            change: {
              title: 'Change Payment Password',
              warning:
                'Please note that all payouts and transfers will be suspended for 24 hours after payment password change.',
            },

            steps: {
              setUpPassword: 'Set Up Password',
              securityCheck: 'Security Check',
              completed: 'Done',
            },

            tooltip: {
              activation:
                'Please note that all payouts and transfers will be suspended for 24 hours after payment password change.',
            },
          },
        },
      },

      signInHistory: {
        title: 'Sign In History',
        disclaimer: 'If you did not perform any of these actions, please change your password immediately.',
        headers: {
          singInTime: 'Sign In Time',
          location: 'Location',
          ip: 'IP',
          browser: 'Browser',
          device: 'Device',
          status: {
            title: 'Status',
            success: 'Success',
            failure: 'Failure',
          },
        },
      },
    },

    workersPage: {
      myGroupTitle: 'My Groups',
      createGrpBtn: 'Create group',
      manageGrpBtn: 'Manage groups',
      workersTitle: 'Worker List',
      searchPlaceholder: 'Enter worker name',

      filters: {
        all: 'All',
        active: 'Active',
        offline: 'Offline',
        inactive: 'Inactive',
      },

      tooltip: {
        active: 'At least 1 share sent within the last hour',
        inactive: 'No shares for more than 1 hour',
        offline: 'No shares for more than 24 hours',
      },

      emptyName: 'No name',

      tableHeaders: {
        workerName: 'Worker',
        h5: '10 Min Average',
        h1h: '1 Hour Average',
        h1d: '1 Day Average',
        rejectRate: 'Reject Rate',
        lastSubmit: 'Last Submit',

        status: {
          title: 'Status',
          active: 'Active',
          inactive: 'Inactive',
          offline: 'Offline',
        },

        operation: 'Operations',
      },

      modal: {
        removeWorkers: {
          title: 'Remove Worker(s)',
          desc: 'Are you sure you want to permanently remove {{count}} offline worker(s) from this page?',
          note: "When the worker starts mining on Neopool again it's data will reappear on this page",
        },
      },

      configGuide: {
        title: 'Worker Configuration Guide',

        instructions: {
          connectToLan: {
            title: 'Connect',
            desc: "Connect your device to the network using LAN, then connect your computer to the same network. Look for your worker's IP. Log into the configuration page of your worker from your computer.",
          },

          minerConfig: {
            title: 'Configure',
            desc: 'Enter the main and backup Stratum URLs (you can copy/paste them from the example section). Enter a workername for your worker: it should consist of numbers and lowercase letters (<64 characters), and should be separated from your account name by a dot. The example section has a valid unique workername for you to use. Choose a password (optional).',
          },

          viewHashrate: {
            title: 'View',
            desc: 'Save the settings. Visit your workers page to check if your newly configured worker is up and running. Note that it usually takes a few minutes for the worker to automatically show up on the workers page.',
          },
        },

        config: {
          title: 'Worker Configuration Example',

          columns: {
            pool: 'Server {{index}} URL',
            worker: 'Worker name',
            password: 'Password',
          },

          otherPoolStratum: 'Add another stratum from a third-party pool as a backup',

          button: {
            copyHostingInfo: 'Copy info for hosting: account name + stratum URL',
          },
        },
      },
    },

    miningAccounts: {
      title: 'Mining Accounts',
      createSubAccTitle: 'Create Sub-account',
      accInputName: 'Sub-account name',
      hiddenAccCheckbox: 'Show hidden accounts',
      createSubAccBtn: 'Create Sub-account',
      manageSubAccBtn: 'Manage sub-accounts',
      createSuccess: 'Sub-account successfully created',
      hideConfirmTitle: 'Are you sure you want to hide this account?',
      hideErrorTitle: 'Account cannot be hidden',
      hideConfirm: 'This action will hide the account permanently. You cannot undo this action.',
      hideAccountTootltip: 'Please switch to your main account to manage subaccounts',

      tableHeaders: {
        account: 'Account',
        allAccounts: 'All accounts',
        BTCRevenue: 'BTC Revenue',
        total: 'Total',
        balance: 'Balance',
        minerStatus: 'Worker Status',
        online: 'Active',
        offline: 'Offline',
        inactive: 'Inactive',
        BTCHashrate: 'Hashrate',
        day: '24h',
        note: 'Note',
        actions: 'Actions',
      },

      hideModal: {
        message: {
          activeWorkers: {
            title: 'This account has workers that are active or were active recently: {{value}}.',
            desc: 'Please assign these workers to another account before you hide this account.',
          },
          notEmptyBalance: {
            title: "This account's current balance is {{value}}.",
            desc: 'Please transfer these funds to another account before you hide this account.',
          },
          watcherLinks: {
            title: 'You currently have active watcher links for this account.',
            desc: 'Please note that these links will become void after the account will have been hidden.',
          },
          autoWithdrawal: {
            title: 'You currently have auto-withdrawal / auto-transfer settings in place which involve this account.',
            desc: 'Please note that these settings will become void after the account will have been hidden.',
          },
        },
      },

      modal: {
        editAccount: {
          title: 'Edit Account',
        },
      },
    },

    paymentSettings: {
      modal: {
        btcAddress: {
          title: 'Set Up Payment Address',

          steps: {
            paymentAddress: 'Wallet Address',
            auth: 'Security Check',
            completed: 'Done',
          },
        },

        modifyPayment: {
          title: 'Modify Auto Withdrawal Threshold',

          label: {
            minAmount: 'Minimal threshold',
            currentPayment: 'Current threshold',
            activation: 'Auto withdrawal activation',
          },

          steps: {
            withdrawalThreshold: 'Withdrawal Threshold',
            auth: 'Authentication',
            completed: 'Done',
          },
        },

        withdrawal: {
          title: 'Withdrawal',

          steps: {
            withdrawal: 'Withdrawal',
            auth: 'Authentication',
            completed: 'Done',
          },

          rules: {
            rule1:
              'ZERO fee and no need for confirmation for Inter-user Transfer, with no withdrawal threshold and no confirmation from as low as 1 satoshi.',
            rule2:
              'Payout to a BTC wallet is subject to a commission fee. Fee is calculated right before withdrawal and may fluctuate substantially depending on the BTC network state. Withdrawal threshold for a payout is 0.001 BTC.',
            rule3: 'Manual auditing is required for withdrawals over 5 BTC which is usually done within 24 hours.',
            rule4:
              'Please note that we currently support payouts only to BTC wallets, please make sure that the payout address is correct.',
          },

          form: {
            label: {
              withdrawalType: 'Withdrawal Type',
              withdrawalAddress: 'Withdrawal Address',
              accountName: 'Account Name',
              amount: 'Amount',
            },

            radio: {
              withdrawalType: {
                interUserTransfer: 'Inter-User Transfer',
                normalTransfer: 'Payout',
                fee: 'Fee {{fee}} BTC',
              },
            },

            button: {
              withdraw: 'Withdraw',
            },
          },
        },

        internalTransfer: {
          title: 'Internal Transfer Settings',

          form: {
            label: {
              accountTo: 'Transfer to',
              accountFrom: 'Transfer from',
              accounts: 'accounts',
              activation: 'Transfer activation',
            },
          },

          label: {
            unavailable: 'Internal transfer from this account already configured on another account',
            value: 'from <accent>{{accountCount}} accounts</accent> - to <accent>{{accountName}}</accent>',
          },

          steps: {
            internalTransfer: 'Internal Transfer',
            auth: 'Authentication',
            completed: 'Done',
          },
        },
      },

      tooltip: {
        autoWithdrawal:
          'Auto withdrawal is made every day {{time}} (UTC) FREE of charge. Current minimum payment threshold: 0.001 BTC. Auto Withdrawal can be made when the account balance reaches your set threshold',

        internalTransfer:
          'Auto internal transfers are made during {{time}} (UTC) once a day for free with no minimal transfer amount.',
      },

      switcher: {
        error: {
          noData: {
            title: 'Error',
            desc: 'Internal transfer cannot be enabled',
          },

          accountAlreadyConfigured: {
            title: 'Internal transfer cannot be enabled',
            desc: 'Internal transfer from this account already configured on another account',
          },

          noWallet: {
            title: 'No Wallet',
            desc: 'To enable auto withdrawal please add your BTC address',
          },
        },
      },
    },

    miningSettings: {
      // miningServers: {
      //   table: {
      //     title: 'Mining Servers',
      //     column: {
      //       poolType: 'Pool Type',
      //       switchAddress: 'Pool Url',
      //       youAreMining: 'You’re mining',
      //     },
      //   },
      // },

      hashrateFluctuationNotification: {
        table: {
          title: 'Hashrate Fluctuation Notification',
          column: {
            coinType: 'Coin Type',
            thresholds: 'Thresholds',
            fluctation: 'Fluctuation',
            activeWorkers: 'Active Workers',
            actions: 'Actions',
          },

          field: {
            thresholds: 'Thresholds',
            fluctuation: 'Fluctation',
            activeWorkers: 'Active workers',
          },
        },

        tooltip: {
          fluct:
            'Fluctuation is a percentage by which a 10-minute average hashrate falls below a 1-day average hashrate. To minimize false-positive notifications recommended fluctuation threshold setting is 25%.',
        },

        modal: {
          notificationSettings: {
            title: 'Notification Settings',

            steps: {
              notifications: 'Notifications',
              frequency: 'Frequency',
              email: 'E-mail',
              telegram: 'Telegram',
            },
          },
        },

        form: {
          label: {
            frequency: 'Frequency',
            email: 'E-mail',
          },
        },

        info: {
          account: {
            title: 'Account',
          },

          frequency: {
            title: 'Frequency',
            value: 'Min. notification interval - {{interval}} hours',
          },

          emails: {
            title: 'E-mail',
          },

          phone: {
            title: 'Phone',
          },

          telegram: {
            title: 'Telegram',
          },
        },

        progress: {
          title: 'Notification Settings Progress',
          description: 'To receive notifications you must configure all settings',

          items: {
            frequency: 'Frequency',
            emailOrTelegram: 'E-mail or Telegram',
          },

          tooltip: {
            frequency: 'To receive notifications you need to add frequency',
            emailOrTelegram: 'To receive notifications you need to add e-mail or telegram',
          },
        },
      },
    },

    watcher: {
      mySharing: {
        title: 'My Watcher URLs',
        desc: 'Anyone can view your account data from your Watcher URL but they have no authority to make modifications.',
        createBtn: 'Create a Watcher',

        table: {
          miningAccount: 'Mining Account',
          url: 'URL',
          Remark: 'Description',
        },

        modal: {
          create: {
            title: 'Create a Watcher',
          },

          delete: {
            title: 'Are you sure you want to delete this watcher link?',
            description:
              'This action will delete the watcher link permanently. You cannot undo this action. After the link is deleted people who were provided with this link will no longer have access to your account information.',
            confirmBtn: 'Confirm',
            cancelBtn: 'Cancel',
          },

          permissions: {
            title: 'Permission Settings',
          },
        },

        notification: {
          delete: {
            success: 'Watcher Link deleted successfully',
            error: 'Cannot delete watcher link. Please try again',
          },

          create: {
            success: 'Watcher Link created successfully',
            error: 'Cannot create watcher link. Please try again',
          },
        },

        createSteps: {
          create: 'Create a Watcher',
          auth: 'Authentication',
          completed: 'Done',
        },

        createForm: {
          fields: {
            gaCode: 'Google Authenticator code',
            watcherType: 'Watcher Type',
            miningAccount: 'Sub-account',
            note: 'Short description',
            permissionSettings: 'Permission Settings',
          },

          btn: {
            confirm: 'Confirm',
          },
        },
      },

      // favorites: {
      //   title: 'Favorites',
      //   desc:
      //     "You can view other's account information from shared" +
      //     ' Watcher URL but have no authority for modification.',
      //   createBtn: 'Add',
      //   manageBtn: 'Manage Favorites',
      //   table: {
      //     miningAccount: 'Mining Account',
      //     url: 'URL',
      //     Remark: 'Remark',
      //   },
      // },

      // externalFavorites: {
      //   title: 'External Link Favorites',
      //   desc:
      //     'To facilitate your management of Watchers,' +
      //     ' you can add Watcher URLs of other mining pools to this Favorites.',
      //   createBtn: 'Add',
      //   manageBtn: 'Manage Favorites',
      //   table: {
      //     url: 'URL',
      //     Remark: 'Remark',
      //   },
      // },

      errors: {
        emptyAccessKey: {
          title: 'Missing access key',
          subTitle: 'Watcher link with no access key is not valid. Please try another link.',
        },

        invalidWatcherLink: {
          title: 'Invalid watcher link',
          subTitle: 'Watcher link invalid, deleted or or expired. Please try another link',
        },

        protectedWatcherLinkPath: {
          title: 'No Access',
          subTitle: 'Access to this page via this watcher link is not allowed',
        },
      },

      watcherLink: {
        successClipBoard: 'Watcher URL copied to clipboard',
        errorClipBoard: 'Watcher link not copied. Please refresh the page and try again.',

        types: {
          independent: 'Sub-account Watcher',
          main: 'Main Watcher (with access to the data of all your mining accounts)',
        },
      },

      permissions: {
        dashboard: 'Dashboard',
        workers: 'Workers',
        earnings: 'Revenue',
        assets: 'Assets',
      },
    },

    notificationsPage: {
      label: {
        announcement: 'News and announcements (via e-mail)',
        autoWithdrawal: 'Auto withdrawal notifications (via e-mail)',
        dailyMiningProfits: 'Daily Mining Revenue Report (via e-mail)',
        monthlyMiningProfits: 'Monthly Mining Revenue Report (via e-mail)',
      },

      tooltip: {
        announcement: 'Notifications will be sent to your main account e-mail',
      },
    },

    apiManagement: {
      table: {
        title: 'API Management',
        desc: 'API secret key is an equivalent of password for your API key. Please store it safely.',

        columns: {
          apiKey: 'API Key',
          accessLevel: 'Access Level',
          note: 'Note',
          actions: 'Actions',
        },

        cells: {
          ipAddresses: '{{count}} IP Addresses',
          sameAsMain: 'Same as main account',
        },
      },

      error: {
        disabled: {
          title: 'API Management Disabled',
          desc: 'If you require help, feel free to contact our support team.',
        },
      },

      actions: {
        create: 'Create API Key',
      },

      modal: {
        editApiKey: {
          title: 'Edit API Key',

          steps: {
            apiKey: 'API Key',
            auth: 'Authentication',
            completed: 'Done',
          },
        },

        removeApiKey: {
          title: 'Remove API Key',
          desc: 'Are you sure you want to remove this API key?',
        },

        checkSecretKey: {
          title: 'View Secret Key',

          steps: {
            auth: 'Authentication',
            secretKey: 'Secret Key',
          },

          labels: {
            secretKey: 'Secret Key',
            apiKey: 'API Key',
          },
        },

        editIpWhitelist: {
          title: 'Set Up IP Whitelist',

          steps: {
            auth: 'Authentication',
            setUp: 'Set Up IP Whitelist',
            completed: 'Done',
          },

          placeholder: {
            ipAddress: 'Enter IP address...',
          },

          whitelistTypes: {
            inheritMain: 'Inherit IP list from main account',
            custom: 'Custom IP adress list',
          },
        },

        showIpWhitelist: {
          title: 'IP Whitelist',
        },
      },

      whitelistTypes: {
        inheritMain: 'Inherit IP list from main account',
        custom: 'Custom IP adress list',
      },

      accessLevel: {
        public: 'Public',
        private: 'Private',
      },

      disclaimer:
        '* By default the sub-account IP whitelist is set to be the same as the main account whitelist. You can then edit the whitelist for each account separately.',
    },

    referralRewards: {
      banner: {
        title: 'Refer friends to mine with us and share rewards together!',

        section: {
          general: {
            title: 'General tier',
            ratio: 'Reward - 10%',
            validity: 'Validity 12 months',
          },

          ambassador: {
            title: 'Ambassador tier',
            ratio: 'Reward - 20%',
            validity: 'Permanent Validity',
          },
        },

        button: {
          apply: 'Apply',
        },

        description: {
          registeredRefereeGet: 'Your friends will get ',
          time: '50% ',
          couponWith: 'of all fees ',
          fee: 'for 30 days',
        },
      },

      referralLinkInfo: {
        referralLink: 'Your Referral Link',
        shareTo: 'Share to',
      },

      overallReferral: {
        title: 'Referral Info',
        param: {
          overallReferral: 'All Referrals',
          cumulativeReferredAccounts: 'Effective Referrals of Last Month',
          cumulativeReferralReward: 'Total Referral Reward',
        },
      },

      table: {
        myReferredUsers: {
          title: 'My Referrals',
          columns: {
            referredAccounts: "Referral's Main Account",
            registrationDate: 'Sign Up Time',
            subAccountsNumber: 'Number of Sub-Accounts',
          },
        },

        myEffectiveReferrals: {
          title: 'My Effective Referrals',
          columns: {
            referredAccounts: "Referral's Main Account",
            registrationDate: 'Sign Up Time',
            subAccountsNumber: 'Number of Sub-Accounts',
          },
        },

        myReferralRecords: {
          title: 'My Referral Rewards',
          columns: {
            date: 'Date (UTC)',
            account: 'Account',
            cumulativeRewards: 'Cumulative Rewards',
          },
        },

        myBeneficiaryRewards: {
          title: 'My Partnership Rewards',

          columns: {
            date: 'Date (UTC)',
            account: 'Account',
            amount: 'Cumulative Rewards',
          },
        },
      },
    },

    vipApplication: {
      section: {
        desc: {
          title: 'VIP Application',
          description: {
            part1: 'Users with large computing power at their disposal can become VIPs',
            part2:
              'VIP users can enjoy such privileges as VIP preferential rates and private endpoints for even higher and more stable income.',
          },
        },
      },

      disclaimer:
        'Users with BTC computing power of 1 PH/s or more can apply to become VIP users. Please provide us with the information about your current hashrate as well as other related information. Our BD will contact you as soon as possible.',
      button: 'High Hashrate VIP Application',
    },

    betaTestApplication: {
      section: {
        desc: {
          title: 'Beta-testing Application',
          description: {
            part1: 'Unique opportunity to test brand new BTC mining pool with 0% commission.',
          },
        },
      },

      button: 'Submit Application',
    },

    myBills: {
      table: {
        title: 'My Bills',
        desc: 'Your revenue and withdrawal history is kept on our servers for 60 days.',

        filter: {
          type: {
            label: 'Type',

            values: {
              all: 'All',
              withdrawal: 'Withdrawal',
              autoWithdrawal: 'Auto Withdrawal',
              miningProfit: 'Mining Revenue',
              referralReward: 'Referral Reward',
              customReferralReward: 'Custom Referral Reward',
              internalTransfer: 'Internal Transfer',
              autoInternalTransfer: 'Auto-internal transfer',
              systemUpdate: 'System Update',
            },
          },

          coinType: {
            label: 'Coin Type',
          },
        },

        columns: {
          time: 'Time (UTC)',
          coinType: 'Coin Type',
          type: 'Type',
          valueChange: 'Balance Change',
          balance: 'Balance',
        },

        columnValues: {
          type: {
            withdrawal: 'Withdrawal',
            autoWithdrawal: 'Auto Withdrawal',
            interUserTransfer: 'Internal transfer',
            internalTransfer: 'Auto-internal transfer',
            PPS: 'FPPS',
            PPLNS: 'FPPS',
            referralReward: 'Referral Reward',
            customReferralReward: 'Custom Referral Reward',
            systemUpdate: 'System Update',
            autoWithdrawalFailed: 'Auto withdrawal - Failed',
            autoWithdrawalFreeze: 'Auto Withdrawal - On hold',
            autoWithdrawalRejected: 'Auto Withdrawal - Refund',
            withdrawalFailed: 'Payout - Failed',
            withdrawalFreeze: 'Payout - On hold',
            withdrawalRejected: 'Payout - Refund',
          },
        },
      },
    },

    depositAndWithdrawal: {
      tabs: {
        deposit: 'Incoming',
        withdrawal: 'Outgoing',
      },

      table: {
        columns: {
          time: 'Time (UTC)',
          coinType: 'Coin',
          type: 'Type',
          coinAmount: 'Coin Amount',
          address: 'Address',
          hash: 'TXID',
          status: 'Status',
          commission: 'Commission',
        },

        filter: {
          type: {
            label: 'Type',
            values: {
              withdrawal: 'Withdrawal',
              autoWithdrawal: 'Auto Withdrawal',
              internalTransfer: 'Internal Transfer',
              autoInternalTransfer: 'Auto-internal Transfer',
            },
          },

          status: {
            label: 'Status',
          },
        },

        emptyHash: 'Transaction will soon be created',
      },

      tooltip: {
        withdrawalTxid: 'Transaction hash is added at the time of payment generation',
      },
    },

    resetProcess: {
      steps: {
        confirmation: {
          title: 'Confirmation',

          reminders: {
            title: 'Reminder',
          },

          content: {
            title: 'Please note',
          },
        },

        accountVerification: {
          title: 'Account Verification',

          content: {
            title: 'Account Verification',
          },
        },

        securityVerification: {
          title: 'E-mail Verification',

          content: {
            title: 'Verification code has been sent to your e-mail',
            desc: 'If you cannot find a confirmation e-mail in your inbox, please check your spam or junk mail folder.',
          },
        },

        identityVerification: {
          title: 'Identity Verification',

          content: {
            title: 'Submit Files',
            desc: 'Please upload the following documents. Our support team will review your documents within 7 business days.',

            requirements: {
              title: 'Screenshots of previous e-mails from Neopool',

              first:
                "Screenshots of e-mails sent to you by Neopool (and your E-mails to Neopool). Please make sure that the sender and recipient's e-mail addresses, subject, text and time are clearly visible on the screenshot.",
              second: 'Please submit 1 - {{value}} clear and easily readable screenshots.',
              third: 'Make sure image files are no larger than {{value}}. Allowed file types are: {{types}}.',
            },
          },
        },

        result: {
          title: 'Done',

          content: {
            title: 'Submit successful, your files await review.',
            desc: "Manual screenshot review will be completed within 7 business days. After the review is concluded you'll receive an e-mail with the results.",
          },
        },
      },

      error: {
        title: 'Reset currently not possible ',
        subTitle: 'Page did not load correctly. Please try again later',
      },
    },

    resetPaymentPassword: {
      title: 'Reset Payment Password',

      steps: {
        confirmation: {
          content: {
            noticements: {
              first:
                'After payment password reset all payouts and internal transfers, as long as modifying withdrawal address and auto-transfer settings are suspended for 48 hours.',
              second:
                'After payment password reset your account will temporarily loose extra protection provided by payment password. Please set a new payment password as soon as possible. We also recommend ensuring that you have Two-factor authentication active.',
            },
          },
        },
      },

      error: {
        noPaymentPassword: {
          title: 'No Payment Password',
          subTitle: "You can't reset yourpayment password because it is not set.",
        },

        prevRequestActive: {
          title: 'Reset request pending',
          subTitle:
            'You have recently submitted documents for a payment password reset. Your documents have not yet been reviewed. Please wait for our e-mail after the review is finished.',
        },
      },
    },

    resetGa: {
      title: 'Reset Two-Factor Authentication',

      steps: {
        confirmation: {
          reminders: {
            first: 'If you have access to your Google Authenticator - you can quickly disable it <Link>here</Link>',
            second:
              'If you lost access to your Google Authenticator you can use your one-time recovery codes to disable two-factor authentication and reenable it with a new device <Link>here</Link>',
          },

          content: {
            noticements: {
              first:
                'After payment password reset all payouts and internal transfers, as long as modifying withdrawal address and auto-transfer settings are suspended for 48 hours.',
              second:
                'After payment password reset your account will temporarily loose extra protection provided by Google Authenticator. Please reactivate two-factor authentication as soon as possible.',
            },
          },
        },
      },

      error: {
        noGa: {
          title: 'Two-factor authentication disabled',
          subTitle:
            "You can't reset your Google Authenticator because it is disabled. To enable it on a new device please click here",
        },

        prevRequestActive: {
          title: 'Reset request pending',
          subTitle:
            'You have recently submitted documents for a Google Authenticator reset. Your documents have not yet been reviewed. Please wait for our e-mail after the review is finished.',
        },
      },
    },

    referralRewardsIntro: {
      title1: 'Introducing Neopool Referral Program:',
      title2: 'Earn BTC Without Mining!',
      desc1: 'Join our referral program and start earning rewards today! ',
      desc2: "It's our way of saying thank you for helping others to join our community!",
      desc3: "Let's dive into the details",

      tiers: {
        normal: {
          title: 'Normal Tier:',
          desc1:
            "— Enjoy rewards equalling generous <accent>10% off our comission</accent> applied to your friend's mining rewards <accent>for a period of 12 months</accent>.",
          desc2:
            "That's right - each day your friends mine with us, you earn a percentage of pool commission back in BTC!",
        },

        ambassador: {
          title: 'Ambassador Tier:',
          desc1:
            "— As an Ambassador, you'll receive a whopping <accent>20% off our comission</accent> applied to your referrals, and this reward is <accent>infinite</accent>!",
          desc2: "That means you'll continue earning cashback for as long as your referrals keep mining.",
        },
      },

      friendsBonus: {
        title: "What's in it for my friends?",
        desc: 'As a special bonus, your referred friends will receive a fantastic <accent>50% discount off</accent> their PPS commission for their <accent>first 30 days</accent> of mining.',
      },

      info: {
        title4: 'How to Get Started:',
        list4: {
          item1: 'Check your <Link>{{link}}</Link> and find a unique referral link.',
          item2: 'Share your referral link with friends, family, and colleagues. ',
          item3:
            "When someone uses your referral link to register and starts mining, you'll start earning referral rewards. You can check your rewards on your <Link>{{link}}</Link>.",
          referralRewardLink: 'referral rewards page',
          item4:
            'When you have 5 of more active referrals you\'ll find a button "Apply for ambassador" on your referral rewards page. Hit this button to apply. Your referrals and their activity will be checked, and if you qualyfy - you\'ll instantly become an ambassador and start earning greater rewards right away.',
        },

        note: 'Note:',
        disclaimer1:
          '* Referral rewards will be credited to your main account automatically. All the details are available on <MyBillsLink>{{myBills}}</MyBillsLink> and <RefRewLink>{{referralRewards}}</RefRewLink> pages.',

        disclaimer2: '** Ambassador status is upheld for as long as you have at least 5 active referrals.',

        links: {
          referralRewards: 'referral rewards',
          myBills: 'my bills',
        },
      },

      join: {
        title1: 'Join our referral program now and start enjoying the perks of being part of our community.',
        title2: 'Happy referring and happy mining!',
      },
    },

    notFoundPage: {
      title: "Oops... We can't find this page",
      description: "It seems the page you're looking for is orphaned or wasn't mined yet.",
    },

    modal: {
      changePhone: {
        title: 'Set Up Phone Number',
      },

      changeFrequency: {
        title: 'Set Up Frequency',
      },

      changeEmail: {
        title: 'Set Up E-mail',
      },

      changeTelegram: {
        title: 'Bind Telegram Account',

        info: {
          instructionOne: {
            first: 'Find our <1>{{value}}</1> on Telegram.',
            second: 'Click /start or use /getID command to get your chat ID',
            third: 'Return to this page and save your chat ID.',
          },

          instructionTwo: {
            title: "You may set alerts to be sent to a Telegram group. Here's how:",

            first:
              'Add the <1>{{value}}</1> into a group you want to send notifications to and send /getID to the group to get a chat ID (group chat id starts with a minus sign)',
            second: 'Return to this page and save your chat ID (keep the minus sign)',
          },
        },
      },

      exportToEmail: {
        title: 'Export to the E-mail',
      },

      securityNotice: {
        title: 'Security reminder',

        message: {
          bindPayPasswordOrGa:
            'To ensure safety of your account please bind your payment password and set up Google Authenticator',
          bindPayPassword: 'To ensure safety of your account please bind your payment password',
          bindGa: 'To ensure safety of your account please set up Google Authenticator',
        },

        button: {
          bindPayPassword: 'Bind payment password',
          bindGa: 'Set up Google Authenticator',
        },
      },

      disablePaymentPassword: {
        title: 'Security Verification',
      },

      authentication: {
        title: 'Security Verification',
      },

      paymentBlock: {
        title: 'Payout Operations Blocked',
        desc: 'For the security reasons payout operations for your account are not allowed till {{unlockDate}}.',

        disabledOperations: 'Disabled operations:',

        operations: {
          withdrawal: 'withdrawal (including internal transfers)',
          autoWithdrawal: 'auto-withdrawal',
          settingsChange: 'auto-transfer settings change',
          walletChange: 'wallet change',
        },

        thanks: 'Thank you for your understanding.',
      },

      userEmail: {
        title: 'Set Up E-mail',

        steps: {
          email: 'E-mail',
          auth: 'Authentication',
          confirmation: 'Confirmation',
          completed: 'Done',
        },

        alert: {
          codeSent:
            'A confirmation code has been sent to your e-mail <Email>{{email}}</Email>. Please enter code in the field below.',
        },
      },

      signInPassword: {
        title: 'Set Up Sign In Password',

        steps: {
          password: 'Password',
          auth: 'Authentication',
          completed: 'Done',
        },
      },
    },

    widgets: {
      chatBot: {
        title: 'Neopool Chat',
      },
    },

    form: {
      label: {
        username: 'Username',
        contact: 'Your Name',
        companyName: 'Company Name',
        countryOrRegion: 'Country / Region',
        telegram: 'Telegram',
        email: 'E-mail',
        telephone: 'Phone',
        explainNeeds: 'Hashrate at your disposal',
        password: 'Password',
        passwordConfirm: 'Confirm password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        gaCode: 'Google Authenticator code',
        recoveryCode: 'Recovery code',
        paymentPassword: 'Payment Password',
        currentPaymentPassword: 'Current payment password',
        phoneNumber: 'Phone Number',
        thresholds: 'Thresholds',
        fluctuation: 'Fluctuation',
        activeWorkers: 'Active Workers',
        newPaymentPassword: 'New Payment Password (6 digits)',
        newPaymentPasswordConfirm: 'Confirm New Payment Password (6 digits)',
        telegramChatId: 'Telegram Chat ID',
        deduction: 'Charge',
        comission: 'Commission',
        wallet: 'Wallet address',
        frequency: 'Frequency',
        note: 'Note',
        accessLevel: 'Access level',
        withdrawalThreshold: 'Withdrawal Threshold',
      },

      button: {
        export: 'Export',
        submit: 'Submit',
        confirm: 'Confirm',
        agreeAndContinue: 'Accept and continue',
        disagree: 'Reject',
        save: 'Save',
        check: 'Check',
        removeAll: 'Remove All',
        goBack: 'Go Back',
        next: 'Next',
        resetCode: 'Resend code',
        browse: 'Browse',
        gotIt: 'Got it',
        signUp: 'Sign Up',
        logIn: 'Log In',
        sendLink: 'Send a link',
        hide: 'Hide',
        yesHide: 'Yes, hide it.',
        cancel: 'Cancel',
        done: 'Done',
        create: 'Create',
        search: 'Search',
        view: 'View',
        edit: 'Edit',
        learnMore: 'Learn more',
        joinNow: 'Join Now',
        getVipApp: 'Get VIP Application',
        autorefresh: 'Autorefresh',
        refresh: 'Refresh',
        skip: 'Skip',
        remove: 'Remove',

        password: {
          reset: 'Reset password',
        },

        appStore: {
          title: 'Download on the',
          platform: 'App Store',
        },

        googlePlay: {
          title: 'GET IT ON',
          platform: 'Google Play',
        },
      },
    },

    notification: {
      success: {
        changesSaved: 'Success. Changes saved.',
        changeCurrentPayment: 'Auto-withdrawal threshold changed successfully',
        withdrawal: 'Withdrawal request sent',
        twoFAEnabled: 'Two-factor authentication enabled successfully',
        changePhone: 'Phone number changed successfully',
        changeTelegram: 'Telegram chat ID changed successfully',
        changeNotifications: 'Notification settings updated successfully',
        changePaymentPassword: 'Success. Payment password changed.',
        resendVerificationCode: 'Verification code resent',
        removeApiKey: 'Success. API key removed successfully',
        editApiKey: 'Success. API key changed successfully',
        createApiKey: 'Success. API key created successfully',
      },

      error: {
        internalTransfer: 'Internal transfer failed. Please try again later.',

        files: {
          tooManyFiles: 'Total number of files must not exceed {{value}}',
          fileTooSmall: 'File size must be greater than {{value}}',
          fileTooLarge: 'File size must be less than {{value}}',
          fileInvalidType: 'Invalid file type',
        },
      },

      internal: {
        reset2fa:
          "We've noticed you used a recovery code to log in. If you lost access to the device with the Google Authenticator app you'll need to disable two-factor authentication on the <Link>settings page</Link> and re-reable it with a new device.",
        noInternet: 'No internet connection. Please check your connection and reload the page',
        paymentBlock:
          'For the security reasons payout operations for your account are not allowed till {{unlockDate}}. Disabled operations: withdrawal (including internal transfers), auto-withdrawal and auto-transfer settings change, wallet change. Thank you for your understanding.',
      },
    },

    status: {
      transaction: {
        sent: 'Sent',
        failed: 'Failed',
        rejected: 'Rejected',
        onHold: 'On Hold',
      },
    },

    link: {
      myBills: 'My Bills',
      depositAndWithdrawal: 'Transfers & Withdrawals',
    },

    common: {
      yes: 'Yes',
      no: 'No',
      on: 'on',
      off: 'off',
      all: 'All',
      from: 'from',
      to: 'to',
      or: 'or',
      noData: 'No data',
      notSet: 'Not set',
      enabled: 'Enabled',
      items: 'items',
      main: 'Main',
      mainLowerCase: 'main',
      subAccountLowerCase: 'sub-account',
      addresses: '{{count}} addresses',
      accounts: '{{value}} accounts',
      day: '1 day',
      days: '{{days}} days',
      noAccess: 'No Access',
      loading: 'Loading...',
      hours: '{{hours}} hours',
      hour: '1 hour',
      dropFiles: 'Drop your files here',
      coin: 'Coin',

      table: {
        actions: {
          delete: 'Delete',
          copy: 'Copy',
          edit: 'Edit',
        },
      },

      forgotLinks: {
        paymentPassword: 'Forgot Payment Password?',
        gaNoAccess: 'I have no access to Google Authenticator',
        ga: 'Reset Google Authenticator and recovery codes',
        recoveryCodes: 'Lost your recovery codes?',
      },

      interval: {
        '5sec': '5 seconds',
        '10sec': '10 seconds',
        '30sec': '30 seconds',
        '1min': '1 minute',
        '3min': '3 minutes',
        '10min': '10 minutes',
        '30min': '30 minutes',
        '1h': '1 hour',
      },
    },

    payment: {
      paymentSettings: {
        title: 'Payment Settings',
        balance: 'Balance (BTC)',
        minimalPayment: 'Minimal Payment (BTC)',
        paymentTime: 'Payment Time (UTC)',
        BTCAddress: 'BTC Address',
        currentPayment: 'Auto Withdrawal',
        walletAddressLabel: 'Wallet address',
        newWalletSet: 'Wallet saved',
        assets: 'Assets',
        internalTransfer: 'Auto Internal Transfer',
      },
    },

    tooltip: {
      allowedWalletTypes: 'Allowed wallet types:',
      copied: 'Copied',
      copy: 'Copy',
      copyRow: 'Copy Row',

      balanceTooltip: {
        onHold: 'On hold',
        paymentDue: 'Payment ETA',
      },
    },

    validation: {
      required: 'This field is required',
      digitsOnly: 'Must contains digits only',
      lowerCaseOnly: 'Lowercase letters only',
      lettersNumbersOnly: 'Lowercase letters and numbers only',
      hasUpperCase: 'Uppercase letters',
      hasLowerCase: 'Lowercase letters',
      hasNumber: 'Numbers',
      hasSpecialChar: 'Special characters',
      mustMatch: 'Fields should match',
      beginsWithLetter: 'Must start with a lowercase letter',
      fractionalNumbers: 'Must be numeric',
      naturalNumbers: 'Should be a number',
      walletAddress: 'Wallet address is not supported',
      phoneNumber: 'Phone number is not valid',
      integer: 'Must be integer',
      telegramChatId: 'Chat ID is not valid',
      confirmAll: 'Please confirm that you agree with all warnings',
      satoshi: 'Must be greater than satoshi',
      noInjection: 'Symbols * < > [ ] { } are invalid',

      password: {
        length: '12 - 32 characters',
        upperCase: 'Uppercase letters',
        lowerCase: 'Lowercase letters',
        numbers: 'Numbers',
        specialCharacters: 'Special characters',
        shouldMatchWithPassword: 'Passwords should match',
        mustMatch: 'Passwords do not match',
      },

      ga: {
        length: 'Code must contain 6 digits',
      },

      paymentPassword: {
        length: 'Must contain 6 digits',
      },

      verificationCode: {
        length: 'Must contain 6 symbols',
      },

      email: {
        required: 'Please enter your e-mail',
        notValid: 'Please enter valid e-mail',
      },

      username: {
        length:
          "Your username must be composed of 2 - 15 lowercase letters and numbers and shouldn't start with a digit",
      },

      сaptcha: {
        required: "Please verify you're human",
      },
    },

    success: {
      export: {
        emailSend: 'Requested data sent to your e-mail',
      },

      vipApplication: 'Success! Please wait for our BD to contact you',
      betaTestApplication: 'Success! Please wait for our BD to contact you',
      referralRationApplication: 'Congratulations! You have successfully become an ambassador',
      changes: 'Success. Changes saved.',
      copy: 'Copied to clipboard',
      workersRemoved: 'Success. Workers removed.',
    },

    error: {
      copy: 'Not copied. Please refresh the page and try again.',
    },

    requestErrors: {
      accountNameAlreadyRegistered: 'This account name is already registered',
      userNameAlreadyRegistered: 'This username is already registered',
      emailAlreadyRegistered: 'This e-mail is already registered',
      invalidParameterValue: 'Invalid values',
      captcha1Failed: 'Please confirm you are not a bot.',
      captcha2Failed: "We can't recognize you as real person. Please try again later",
      incorrectTOTP: 'Error. Invalid credentials',
      incorrectPassword: 'Error. Invalid credentials',
      incorrectExistingPassword: 'Error. Invalid credentials',
      invalidCredentials: 'Error. Invalid credentials',
      invalidCode: 'Error. Invalid verification code',
      userNotFound: 'Error. Invalid credentials',
      tooManyRequests: 'Error. Too many requests. Please try again later',

      forbidden: 'Forbidden',
      serverIsNotResponding: 'Server is not responding',
      unauthorized: 'You are not authorized',
      pageNotFound: 'Page not found',
      badRequest: 'Bad request',

      auth: {
        invalidData: 'Invalid credentials',
        emptyData: 'Please provide required data',
        invalidAccount: 'Error. Invalid recipient account',
      },

      payment: {
        nsf: 'Error. Insufficient funds',
        minLimit: 'Error. Amount must be more than 0',
        block: 'Error. Payout Operations Blocked',
      },

      fee: {
        nsf: 'Withdrawal is currently unavailable. Please try again later or contact support',
        notFound: 'Withdrawal is currently unavailable. Please try again later or contact support',
        lowAmount: 'Amount cannot be less than commission',
        dustAmount: 'Withdrawal amount very low. Please try to withdraw a larger amount.',
        addressNotValid: 'Wallet address is invalid',
        unknown: 'Withdrawal is currently unavailable. Please try again later or contact support',
      },

      ambassador: {
        notEligible:
          "Error. You don't have enough active referrals to become an ambassador ({{activeRefs}} out of {{limitRefs}}).",
      },

      apiKeys: {
        keyLimitReached:
          'Error. API keys limit reached. To change the number of API keys allowed please contact our support',
        accessLevelNotPermitted: 'Error. Access level not permitted',
      },

      common: 'Something went wrong. Please try again later',
    },
  },
};
