import { useQuery } from '@tanstack/react-query';
import { getBanners } from 'services/banners.service';

const useBannersQuery = (options = {}) => {
  return useQuery({
    queryKey: ['banners'],
    queryFn: getBanners,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...options,
  });
};

export default useBannersQuery;
