import React, { useEffect, useRef, useState } from 'react';
import { Table as AntdTable } from 'antd';

import { NoData, Spin } from 'components';
import TableCell from './components/TableCell';

import setTableHeight from './utils/setTableHeight';
import useTableColumns from './hooks/useTableColumns';
import replacePaginationElements from './utils/replacePaginationElements';

import { DefaultPagination } from 'constants/pagination';
import { DEFAULT_PAGINATION, DEFAULT_ROW_HEIGHT } from './constants/pagination';

import './Table.styles.scss';

const Table = ({
  dataSource,
  loading,
  columns,
  pagination,
  className = '',
  scroll,
  stretch,
  dynamicHeight,
  rowHeight = DEFAULT_ROW_HEIGHT,
  onChange,
  ...props
}) => {
  const stretchClass = stretch ? 'np-table--stretch' : '';
  const paginationClass = pagination === false ? 'np-table--no-pagination' : '';

  const tablePagination = pagination === false ? pagination : { ...DEFAULT_PAGINATION, ...pagination };
  const { pageSize, defaultPageSize } = tablePagination;

  const tableRef = useRef(null);

  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize || pageSize || DefaultPagination.PAGE_LIMIT);

  const { columns: sortColumns, onChange: handleChange } = useTableColumns({ columns, onChange });

  const handleTableChange = (pagination, filters, sorter, extra) => {
    handleChange(pagination, filters, sorter, extra);

    setCurrentPageSize(pagination?.pageSize);
  };

  useEffect(() => {
    if (dataSource?.length && tableRef.current) {
      replacePaginationElements(tableRef.current);
    }
  }, [dataSource]);

  useEffect(() => {
    if (!dynamicHeight) {
      return;
    }

    if (currentPageSize && tableRef.current) {
      const options = { pageSize: currentPageSize, rowHeight };

      setTableHeight(tableRef.current, options);
    }
  }, [currentPageSize, rowHeight, dynamicHeight]);

  return (
    <AntdTable
      onChange={handleTableChange}
      columns={sortColumns}
      ref={tableRef}
      dataSource={dataSource}
      className={`np-table ${stretchClass} ${paginationClass} ${className}`}
      loading={loading && { indicator: <Spin /> }}
      locale={{ emptyText: <NoData /> }}
      scroll={{ x: '100%', y: stretch || dynamicHeight ? '100%' : null, scrollToFirstRowOnChange: true, ...scroll }}
      pagination={tablePagination}
      components={{
        body: {
          cell: props => <TableCell {...props} />,
        },
      }}
      {...props}
    />
  );
};

export default Table;
