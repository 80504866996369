import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useTableSorter from './useTableSorter';
import useNotifications from 'hooks/common/useNotifications';

import { CopiableCellClass } from '../constants/classes';

const useTableColumns = ({ columns, onChange = () => {} }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const { columns: sortColumns, onChange: handleChange } = useTableSorter({ columns, onChange });

  const onCopy = useCallback(
    async text => {
      try {
        if (!text) {
          return;
        }

        await navigator.clipboard.writeText(text);
        notificationApi.success({ message: t('success.copy') });
      } catch (e) {
        notificationApi.warning({ message: t('error.copy') });
      }
    },
    [notificationApi, t],
  );

  const generateCopiableColumn = useCallback(
    column => {
      if (!column?.copiable) {
        return column;
      }

      const { className = '', onCell = () => {}, renderCopyValue } = column || {};

      return {
        ...column,
        className: `${CopiableCellClass} ${className}`,
        onCell: (record, index) => {
          onCell(record, index);

          return {
            onClick: async () => {
              const value = record[column?.dataIndex];
              const text = renderCopyValue ? renderCopyValue(value, record) : value;

              onCopy(text);
            },
          };
        },
      };
    },
    [onCopy],
  );

  const newColumns = useMemo(() => {
    return sortColumns.map(column => {
      return generateCopiableColumn(column);
    });
  }, [generateCopiableColumn, sortColumns]);

  return {
    columns: newColumns,
    onChange: handleChange,
  };
};

export default useTableColumns;
