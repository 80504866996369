import React from 'react';

import { Carousel } from 'components';
import Banner from './components/Banner';

import useBannersQuery from './hooks/useBannersQuery';

import { settings } from './constants/settings';

import './BannerCarousel.styles.scss';

const BannerCarousel = () => {
  const { data, isLoading } = useBannersQuery();

  const banners = data?.data || [];

  const firstBanner = banners[0];
  const isStatic = banners.length <= 1;
  const staticClass = isStatic ? 'np-banner-carousel--static' : '';

  if (isLoading || !banners.length) {
    return null;
  }

  return (
    <div className={`np-banner-carousel ${staticClass}`}>
      {isStatic ? (
        <Banner src={firstBanner?.img_url} link={firstBanner?.link} alt={firstBanner?.alt_txt} />
      ) : (
        <Carousel {...settings}>
          {banners.map(banner => (
            <Banner key={banner.id} src={banner?.img_url} link={banner?.link} alt={banner?.alt_txt} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default BannerCarousel;
