import request from './request.service';
import { apiUrls } from 'constants/urls';
import asyncErrorHandler from 'utils/requests/errorHandler';

export const getBanners = async () => {
  const callback = async () => {
    return await request(apiUrls.banners);
  };

  return await asyncErrorHandler(callback, { defaultErrorReturn: [] });
};
