import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

import useCopyText from 'hooks/common/useCopyText';

import { CopyIcon } from 'assets/images';

import './CopyButton.styles.scss';

const CopyButton = ({ text, copied: userCopied, tooltip = 'tooltip.copy', size = 16, onChange = () => {} }) => {
  const { t } = useTranslation();

  const { copied, onCopy } = useCopyText({ copied: userCopied, onChange });

  const tooltipText = copied ? t('tooltip.copied') : t(tooltip);

  const handleCopy = e => {
    e.stopPropagation();
    onCopy(text);
  };

  return (
    <Tooltip title={t(tooltipText)}>
      {copied ? (
        <CheckOutlined className="np-copy-button" style={{ width: size, height: size }} />
      ) : (
        <CopyIcon className="np-copy-button" onClick={handleCopy} style={{ width: size, height: size }} />
      )}
    </Tooltip>
  );
};

export default CopyButton;
