import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useNotifications from './useNotifications';

const COPIED_MS = 3000;

const useCopyText = (options = {}) => {
  const { copied: userCopied, onChange = () => {}, showSuccessNotification } = options;

  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [copied, setCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const onChangeCopied = useCallback(() => {
    setCopied(true);
    onChange(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      setCopied(false);
      onChange(false);
    }, COPIED_MS);

    setTimeoutId(id);
  }, [onChange, timeoutId]);

  const onCopy = useCallback(
    async text => {
      try {
        if (!text) {
          return;
        }

        await navigator.clipboard.writeText(text);

        onChangeCopied();

        if (showSuccessNotification) {
          notificationApi.success({ message: t('success.copy') });
        }
      } catch (e) {
        notificationApi.warning({ message: t('error.copy') });
      }
    },
    [notificationApi, onChangeCopied, showSuccessNotification, t],
  );

  useEffect(() => {
    if (userCopied) {
      onChangeCopied();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCopied]);

  return { onCopy, copied };
};

export default useCopyText;
