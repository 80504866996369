import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import useTheme from 'hooks/common/useTheme';

import { CopiableCellClass } from '../../constants/classes';

const TableCell = ({ ...props }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const isCopiable = props.className.includes(CopiableCellClass);

  if (!isCopiable) {
    return <td {...props} />;
  }

  return (
    <Tooltip
      placement="right"
      title={t('tooltip.copy')}
      color={colors.PRIMARY_COLOR_70}
      overlayClassName="np-table-cell__tooltip"
    >
      <td {...props} />
    </Tooltip>
  );
};

export default TableCell;
