import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StorageKey } from 'constants/localStorage';

const useReferralToken = () => {
  const [urlParams] = useSearchParams();

  const [token, setToken] = useState(null);

  useEffect(() => {
    const urlToken = urlParams.get('token');
    const storageToken = localStorage.getItem(StorageKey.SIGN_UP_TOKEN);

    if (urlToken) {
      localStorage.setItem(StorageKey.SIGN_UP_TOKEN, urlToken);
    }

    setToken(urlToken || storageToken);
  }, [urlParams]);

  return token;
};

export default useReferralToken;
